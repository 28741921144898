import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  change(event) {
    const target = event.currentTarget;
    const value = target.value.replace(/[０-９]/g, (n) => '０１２３４５６７８９'.indexOf(n)).replace(/[^0-9]/g, '');
    if(value.length > 0) {
      target.value = value;
    } else {
      target.value = '0';
    }
  }
}
