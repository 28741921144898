import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'selectAllCheckbox'];

  toggleAll({ target }) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = target.checked;
    });
  }

  click(e) {
    const checkedTourArrangementId = this.checkboxTargets.map((checkbox) => {
      if (!checkbox.checked) return null;
      const { tourArrangementId } = checkbox.dataset;
      return tourArrangementId;
    }).filter((v) => v);

    if (checkedTourArrangementId.length === 0) {
      e.preventDefault();
      alert('手配を選択してください');
    } else {
      const message = '選択した手配を見積に反映します。\n入力済の内容はクリアされます。\nよろしいですか？';
      if (!window.confirm(message)) {
        e.preventDefault();
      } else {
        const url = new URL(e.target.href);
        url.searchParams.delete('selected_tour_arrangement_ids[]');

        checkedTourArrangementId.forEach((id) => {
          url.searchParams.append('selected_tour_arrangement_ids[]', id);
        });
        e.target.href = url;
      }
    }
  }
}
